import React, { useEffect, ReactNode, useState, useCallback } from "react"
import { Helmet } from "react-helmet"
import { setTrackingCookies } from "../assets/helper/setTrackingCookies"
import { useWindowDimensions } from "../hooks/useWindowDimensions"
import { useLogos } from "../hooks/queries/settings/useLogos"
import { Footer } from "./footer/Footer"
import { SecondaryHeader } from "./secondary-header/SecondaryHeader"
import { Header } from "./main-header/Header"
import { SideNav } from "./side-nav/SideNav"
import { useNavigationItems } from "../hooks/useNavigationItems"
import { useGeneralSettings } from "../hooks/useGeneralSettings"
import { LayoutContext } from "contexts/components/LayoutContext"
import { HeaderNavigation } from "./header-navigation/HeaderNavigation"
import scrollToElement from "scroll-to-element"
import { appendScript, appendInlineScript } from "../assets/helper/appendScript"
import { PhoneIcon } from "./main-header/PhoneIcon"
import { TrackingNumberModal } from "./modal/TrackingNumberModal/TrackingNumberModal"
import { AdditionalLocations } from "./footer/AdditionalLocations"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import { GoogleReview } from "./google-reviews-banner/GoogleReview"
import { AlertBanner } from "../components/alert-banner/AlertBanner"
import { useSideNav } from "hooks/useSideNav"
import { StubBanner } from "./form-stub/StubBanner"

interface LayoutProps {
  children: ReactNode
  pathName: string
  location: string
  hash?: string
  linkedPagesUrlPathCodex: Record<string, string>
  mobilePreload?: string
  parentSlug?: string
  isHomepage?: boolean
  isLoginPage?: boolean
}

export const Layout = ({
  children,
  location,
  pathName,
  hash,
  mobilePreload,
  linkedPagesUrlPathCodex,
  parentSlug,
  isHomepage = false,
  isLoginPage = false,
}: LayoutProps) => {
  const { isNavOpen } = useSideNav()
  const { size, isScrolled } = useWindowDimensions()
  const slug =
    location.startsWith("/") && location !== "/"
      ? location.substring(1)
      : "home"

  let scrollClass = ""

  if (isScrolled) scrollClass = "page-content--scroll-version"

  const {
    customCSSUrl,
    scripts,
    globalFormStubBanner,
    isAlertEnabled,
    alertBanner,
    domainVerification,
  } = useGeneralSettings()

  const { googleReviewsUrl } = useIntegrationsSettings()

  const {
    secondaryHeaderNavigation,
    headerNavigation,
    footerNavigation,
    creditsNavigation,
    sideNavigation,
  } = useNavigationItems()

  const { footerLogo, headerLogo, sideNavLogo } = useLogos()
  const [isTrackingModalOpen, setTrackingModalOpen] = useState(false)
  const [isFooterLocationsOpen, setFooterLocationsOpen] = useState(false)

  const handleOpenTrackingModal = useCallback(() => {
    setTrackingModalOpen(true)
  }, [])

  const handleCloseTrackingModal = useCallback(() => {
    setTrackingModalOpen(false)
  }, [])

  const handleOpenFooterLocations = useCallback(() => {
    setFooterLocationsOpen(true)
  }, [])

  const handleCloseFooterLocations = useCallback(() => {
    setFooterLocationsOpen(false)
  }, [])

  useEffect(() => {
    setTrackingCookies()
    if (window) {
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormSubmit"
        ) {
          console.log("Form Submitted!", event)
          window.localStorage.setItem("leadSaved", "True")
        }
      })
    }
    if (scripts) {
      scripts?.modular_content.map((script, index) => {
        script.elements.src_value?.value !== "" &&
          appendScript({
            id: `globalScript-${index + 1}`,
            scriptToAppend: script.elements.src_value?.value || "",
            isAsync: true,
          })

        script.elements.script?.value !== "" &&
          appendInlineScript({
            id: `globalScriptInline-${index + 1}`,
            scriptToAppend: script.elements.script?.value || "",
            isAsync: false,
          })
      })
    }
  }, [])

  useEffect(() => {
    if (!hash) return
    let mounted = true

    setTimeout(() => {
      if (mounted) {
        scrollToElement(hash, {
          duration: 1000,
          offset: -200,
        })
      }
    }, 200)

    return () => {
      mounted = false
    }
  }, [hash])

  const pageContentModifierAlertEnabled =
    isHomepage && isAlertEnabled ? "page-content--alert-banner-enabled" : ""

  return (
    <LayoutContext.Provider
      value={{
        linkedPagesUrlPathCodex,
        breadcrumbLocation: pathName,
        slug: location,
        parentSlug,
      }}
    >
      <div className={`wrapper wrapper--${isHomepage ? "home" : slug}`}>
        <Helmet
          meta={
            domainVerification && [
              { name: "google-site-verification", content: domainVerification },
            ]
          }
        >
          {customCSSUrl && <link rel="stylesheet" href={customCSSUrl} />}
          {mobilePreload && size > 0 && size < 768 && (
            <link rel="preload" as="image" href={mobilePreload} />
          )}
        </Helmet>

        {size >= 1366 && (
          <SecondaryHeader>
            <nav className="secondary-header__nav">
              {secondaryHeaderNavigation.map(navItem => {
                return <HeaderNavigation key={navItem.id} navItem={navItem} />
              })}
            </nav>
          </SecondaryHeader>
        )}
        {isHomepage && isAlertEnabled && alertBanner && (
          <AlertBanner
            date={alertBanner?.date}
            title={alertBanner?.alertText}
            url={alertBanner?.linkUrl}
            iconCodeName={alertBanner?.iconCodeName}
          />
        )}
        <Header logo={headerLogo} isAlertEnabled={isHomepage && isAlertEnabled}>
          {headerNavigation.length > 0 && (
            <nav className={`fr-header__nav`}>
              {headerNavigation.map(navItem => {
                return (
                  <HeaderNavigation
                    key={navItem.id}
                    navItem={navItem}
                    isMain={true}
                  />
                )
              })}
            </nav>
          )}

          {size > 0 && size < 1024 && (
            <PhoneIcon
              isTrackingModalOpen={isTrackingModalOpen}
              handleOpenTrackingModal={handleOpenTrackingModal}
            />
          )}
        </Header>

        <main
          className={`page-content ${scrollClass} ${
            !isLoginPage &&
            globalFormStubBanner &&
            Object.keys(globalFormStubBanner).length > 0
              ? "page-content--stubbanner"
              : ""
          } ${pageContentModifierAlertEnabled}`}
        >
          {children}
        </main>

        {sideNavigation.length > 0 && isNavOpen && (
          <SideNav navItems={sideNavigation} logo={sideNavLogo} />
        )}

        {googleReviewsUrl && <GoogleReview url={googleReviewsUrl} />}

        <Footer
          logo={footerLogo}
          creditsNavigation={creditsNavigation}
          footerNavigation={footerNavigation}
          handleOpenFooterLocation={handleOpenFooterLocations}
          hasCTAOtherLocations
        />
        {globalFormStubBanner &&
          Object.keys(globalFormStubBanner).length > 0 && (
            <StubBanner formStub={globalFormStubBanner} showMobileOnly />
          )}
        {isTrackingModalOpen && (
          <TrackingNumberModal onCloseModal={handleCloseTrackingModal} />
        )}

        <AdditionalLocations
          logo={footerLogo}
          isOpen={isFooterLocationsOpen}
          onCloseLocations={handleCloseFooterLocations}
        />
      </div>
    </LayoutContext.Provider>
  )
}
