import React from "react"
import * as styles from "./alert.module.scss"

export const AlertIcon = () => {
    return (
        <svg className="color-fill" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <g id="Group_545" data-name="Group 545" transform="translate(32.397 -10.074)">
                <path id="Path_15872" data-name="Path 15872" d="M30,12.729C30,5.7,23.284,0,15,0S0,5.7,0,12.729,6.716,25.457,15,25.457a17.486,17.486,0,0,0,4.44-.568A7.264,7.264,0,0,0,26.291,30a10.291,10.291,0,0,1-2.3-7.1A12.185,12.185,0,0,0,30,12.729M15,22.068a1.838,1.838,0,1,1,1.83-1.838A1.834,1.834,0,0,1,15,22.068m1.83-7.488a1.83,1.83,0,1,1-3.66,0V6.752a1.83,1.83,0,1,1,3.66,0Z" transform="translate(-32.397 10.073)" fill="#feca7a" />
            </g>
        </svg>
    )
}
