import React from "react"
import { useGeneralSettings } from "../../hooks/useGeneralSettings"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { FooterLocation } from "./FooterLocation"
import "./additional-locations.module.scss"
import { ArrowDown } from "./ArrowDown"
import { ImageBuilder } from "_types/AssetsImage"
import { Link } from "gatsby"
interface AdditionalLocationsProps {
  onCloseLocations: () => void
  isOpen: boolean
  logo: ImageBuilder
}

export const AdditionalLocations = ({
  onCloseLocations,
  isOpen,
  logo,
}: AdditionalLocationsProps) => {
  const { formattedOffices, trackingNumbers } = useGeneralSettings()
  const { size } = useWindowDimensions()
  return (
    <section
      className={`footer-locations ${isOpen? "footer-locations--open" : ""}`}
    >
      {/* <div className="footer-locations__handle-bar"></div> */}
      <div className="fr-container">
        <div className="footer-locations__header">
          <button
            onClick={onCloseLocations}
            className="footer-locations__hide-button"
          >
            {size >= 768 && <p>Hide Locations</p>}
            <ArrowDown />
          </button>
          <a
            href={`tel: ${trackingNumbers[0].trackingNumber}`}
            className="fr-footer__phone"
          >
            {trackingNumbers[0].trackingNumber}
          </a>
        </div>
        <div className="footer-locations__logo">
          <Link to="/">
            <img
              src={`${logo.url}?q=60&w=300`}
              alt={logo.description}
              loading="lazy"
            />
          </Link>
        </div>

        <div className="footer-locations__body">
          {formattedOffices &&
            formattedOffices.map((office, i) => {
              if (i === 0) {
                return
              } else return <FooterLocation key={office.id} office={office} />
            })}
        </div>
      </div>
    </section>
  )
}
