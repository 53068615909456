import { graphql } from "gatsby"
import React from "react"
import "./alert.module.scss"
import { AlertIcon } from "./AlertIcon"

interface AlertProps {
  iconCodeName?: string
  title?: string
  url?: string
  backgroundColor?: string
  date?: string
}

export const AlertBanner = ({
  title = "",
  url,
  iconCodeName = "",
  backgroundColor = "",
  date,
}: AlertProps) => {
  const getIcon = (codename: string) => {
    switch (codename) {
      case "alert":
        return <AlertIcon />
      default:
        break
    }
  }
  return (
    <div className="emergency-ticker">
      <div
        className="emergency-ticker__marquee"
        style={{ background: backgroundColor }}
      >
        {iconCodeName && getIcon(iconCodeName)}
        {url && title && (
          <a href={url}>
            <span>Update{date ? ` ${date}:` : ":"}</span> {title}
          </a>
        )}
        {!url && title && (
          <p>
            <span>Update{date ? ` ${date}:` : ":"}</span> {title}
          </p>
        )}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment AlertBannerFragment on kontent_item_alert_banner {
    id
    elements {
      alert_text {
        value
      }
      background_color {
        value
      }
      date {
        value(formatString: "M/D/YY")
      }
      icon {
        value {
          codename
        }
      }
      link {
        value
      }
    }
  }
`
