import React from "react"
import { ChildPagesFormatted } from "contexts/settings/Navigation/navigation-types"
import { SubNavChild } from "./SubNavChild"

interface SubNavChildrenProps {
  isOpen: boolean
  childPages: ChildPagesFormatted[]
  handleCloseNav: () => void
}
export const SubNavChildren = ({
  isOpen,
  childPages,
  handleCloseNav,
}: SubNavChildrenProps) => {
  return (
    <ul
      className={
        isOpen
          ? "side-nav__sub-nav-list"
          : "side-nav__sub-nav-list side-nav__sub-nav-list--sub-nav-closed"
      }
    >
      {childPages.map(({ id, slug, linkText }) => {
        return (
          <SubNavChild key={id} slug={slug} handleCloseNav={handleCloseNav}>
            {linkText}
          </SubNavChild>
        )
      })}
    </ul>
  )
}
