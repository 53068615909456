import React from "react"
import { useGeneralSettings } from "hooks/useGeneralSettings"

export const SingleContactNumber = () => {
  const {
    trackingNumbers,
    customTrackingNumberCtaButtonText,
    usePhoneNumberNotTrackingNumber,
  } = useGeneralSettings()

  const trackingPhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.trackingNumber : ""
  const singlePhoneNumber =
    trackingNumbers.length > 0 ? trackingNumbers[0]?.phoneNumber : ""

  const contactNumber = usePhoneNumberNotTrackingNumber
    ? singlePhoneNumber
    : trackingPhoneNumber

  const trackingNumberCTAText =
    customTrackingNumberCtaButtonText || contactNumber

  return (
    <a
      className="button--primary-page-link side-nav__call-tracking-link"
      href={`tel: ${contactNumber}`}
    >
      {trackingNumberCTAText}
    </a>
  )
}
