import React, { useContext } from "react"
import "./form-stub.module.scss"
import { FormFieldsContext } from "contexts/FormFieldsContext"
import { KontentItemFormFieldElements } from "components/forms/formBuilder-types"
import { FormStubBannerButtonValue } from "./formStubBanner-types"
import { AnchorLink } from "components/anchor-link/AnchorLink"
import { useLayout } from "contexts/components/LayoutContext"

interface FormStubProps {
  button: FormStubBannerButtonValue
  field: KontentItemFormFieldElements
}

export const FormStub = ({ button, field }: FormStubProps) => {
  const { button_text, anchor_reference } = button
  const { slug, breadcrumbLocation } = useLayout()
  const { field_type, form_field, label } = field || {}
  const { setEmailAddress } = useContext(FormFieldsContext)
  const setFields = (e: { target: HTMLInputElement }) => {
    const { value } = e?.target
    setEmailAddress(value)
  }

  return (
    <div className={`form-stub`}>
      {field && (
        <div className="form-stub__input">
          <label htmlFor={form_field?.value[0]?.codename}>{label?.value}</label>

          <input
            type={field_type?.value[0].codename}
            name={form_field?.value[0].codename}
            placeholder={
              field_type?.value[0].codename === "email"
                ? "email@example.com"
                : "John Smith"
            }
            onBlur={setFields}
          />
        </div>
      )}
      {breadcrumbLocation && (
        <AnchorLink
          children={button_text.value}
          to={
               `${breadcrumbLocation}#${anchor_reference?.value[0]?.name ? anchor_reference?.value[0]?.name : 'form-banner'}`
          }
          className="button--solid-primary form-stub__button"
        />
      )}
    </div>
  )
}
