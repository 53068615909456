import React from "react"

export const GoogleSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68.317"
      height="22.419"
      viewBox="0 0 68.317 22.419"
    >
      <g
        id="google-newsep-2015-seeklogo.com"
        transform="translate(-0.003 -0.011)"
      >
        <path
          id="Path_15795"
          data-name="Path 15795"
          d="M102.206,84.241h3.69c.055,0,.111,0,.166,0,.11-.007.155.049.167.152.023.211.061.421.076.633a9.165,9.165,0,0,1-.556,3.967,7.052,7.052,0,0,1-2.076,2.859,7.647,7.647,0,0,1-3.959,1.771,8.805,8.805,0,0,1-4.847-.553,8.538,8.538,0,0,1-5.4-6.918,8.189,8.189,0,0,1,1.984-6.684,8.78,8.78,0,0,1,7.665-3.089,8.16,8.16,0,0,1,4.1,1.557,8.748,8.748,0,0,1,.8.661c.086.08.1.141.013.233-.486.494-.967.994-1.452,1.491-.1.1-.106.1-.248-.029a6.039,6.039,0,0,0-4.408-1.556,6.207,6.207,0,0,0-5.835,5.093,6.3,6.3,0,0,0,1.271,5.186,5.913,5.913,0,0,0,3.6,2.188,6.389,6.389,0,0,0,3.778-.34,4.946,4.946,0,0,0,2.522-2.192,5.539,5.539,0,0,0,.594-1.885c.021-.143.011-.153-.155-.154q-.709-.007-1.419-.006H98.541c-.063,0-.126,0-.189,0-.108.005-.156-.037-.155-.152q.005-1.041,0-2.082c0-.113.044-.154.154-.151.2,0,.394,0,.591,0Z"
          transform="translate(-89.37 -76.32)"
          fill="#4486f4"
        />
        <path
          id="Path_15796"
          data-name="Path 15796"
          d="M1529.842,287.751c.07-.062.044-.138.046-.206,0-.173,0-.347,0-.52,0-.1.023-.162.143-.161q1.029.005,2.058,0c.1,0,.147.038.145.138v9.7a8.3,8.3,0,0,1-.339,2.59,4.661,4.661,0,0,1-3.916,3.365,5.767,5.767,0,0,1-2.6-.124,5.438,5.438,0,0,1-3.37-2.936c-.074-.164-.074-.168.1-.24q.9-.38,1.812-.754c.162-.067.167-.064.246.1a3.32,3.32,0,0,0,1.173,1.363,2.878,2.878,0,0,0,1.846.451,3.066,3.066,0,0,0,1.351-.386,2.663,2.663,0,0,0,1.221-1.729,6.99,6.99,0,0,0,.135-1.81c0-.038.006-.088-.035-.105-.08-.034-.109.045-.147.086a3.707,3.707,0,0,1-2.271,1.11,4.708,4.708,0,0,1-2.863-.522,5.661,5.661,0,0,1-1.767-8.655,5.244,5.244,0,0,1,2.879-1.837,4.276,4.276,0,0,1,3.432.5,3.032,3.032,0,0,1,.551.445C1529.717,287.665,1529.759,287.738,1529.842,287.751Zm.209,4.288a3.525,3.525,0,0,0-.249-1.321,2.913,2.913,0,0,0-5.171-.72,3.579,3.579,0,0,0-.658,2.389,3.25,3.25,0,0,0,1.345,2.545,2.873,2.873,0,0,0,4.042-.589A3.637,3.637,0,0,0,1530.051,292.04Z"
          transform="translate(-1479.338 -280.315)"
          fill="#4486f4"
        />
        <path
          id="Path_15797"
          data-name="Path 15797"
          d="M696.755,286.45a5.556,5.556,0,0,1,5.475,6.186,5.509,5.509,0,0,1-5.534,5.013,5.414,5.414,0,0,1-4.9-2.7,5.69,5.69,0,0,1,.888-6.926,5.51,5.51,0,0,1,3.12-1.508C696.087,286.476,696.37,286.471,696.755,286.45Zm3.037,5.484a3.492,3.492,0,0,0-.308-1.386,3.093,3.093,0,0,0-5.184-.8,3.679,3.679,0,0,0-.248,4.283,3.064,3.064,0,0,0,5,.234A3.537,3.537,0,0,0,699.792,291.934Z"
          transform="translate(-673.3 -280.255)"
          fill="#ea4537"
        />
        <path
          id="Path_15798"
          data-name="Path 15798"
          d="M1111.209,297.936a5.459,5.459,0,0,1-5.119-3.232,5.668,5.668,0,0,1,1.168-6.4,5.412,5.412,0,0,1,3.168-1.507,5.608,5.608,0,0,1,3.969.9,5.351,5.351,0,0,1,2.4,4.1,5.547,5.547,0,0,1-5.047,6.122c-.094.011-.188.016-.283.019S1111.3,297.936,1111.209,297.936Zm3.149-5.6a3.512,3.512,0,0,0-.524-1.911,3.081,3.081,0,0,0-5.077-.255,3.679,3.679,0,0,0-.065,4.251,3.065,3.065,0,0,0,4.738.348,3.392,3.392,0,0,0,.928-2.433Z"
          transform="translate(-1075.66 -280.538)"
          fill="#fbbd07"
        />
        <path
          id="Path_15799"
          data-name="Path 15799"
          d="M2067.82,298.079a5.178,5.178,0,0,1-3.874-1.9,5.475,5.475,0,0,1-1.295-3.359,5.875,5.875,0,0,1,.841-3.51,4.91,4.91,0,0,1,4.057-2.417,4.756,4.756,0,0,1,4.312,2.088,8.046,8.046,0,0,1,.992,1.954c.055.142.049.147-.14.225L2069,292.7l-3.344,1.383c-.03.012-.057.027-.088.036-.11.036-.123.1-.068.2a2.744,2.744,0,0,0,1.876,1.467,3.154,3.154,0,0,0,3.4-1.319c.1-.149.106-.151.257-.051q.82.541,1.636,1.085c.147.1.149.1.051.232A5.728,5.728,0,0,1,2067.82,298.079Zm-2.8-5.784c.088-.029.139-.044.188-.065l4.492-1.867c.346-.144.353-.166.124-.466a2.186,2.186,0,0,0-1.539-.824,2.977,2.977,0,0,0-2.906,1.6A3.285,3.285,0,0,0,2065.019,292.3Z"
          transform="translate(-2004.563 -280.679)"
          fill="#ea4537"
        />
        <path
          id="Path_15800"
          data-name="Path 15800"
          d="M1941.884,104.3V96.354c0-.305-.029-.286.274-.285l1.987.005c.2,0,.2,0,.2.193v15.966c0,.331.052.3-.3.3q-.934,0-1.869,0c-.055,0-.111,0-.166,0-.086,0-.134-.029-.128-.122,0-.063,0-.126,0-.189Z"
          transform="translate(-1887.36 -95.476)"
          fill="#36a954"
        />
      </g>
    </svg>
  )
}
