import React from "react"

export const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="arrow" transform="translate(24.002 -186) rotate(90)">
        <g
          id="Group_533"
          data-name="Group 533"
          transform="translate(186 0.002)"
        >
          <path
            id="Path_15868"
            data-name="Path 15868"
            d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0m7.467,19.467A10.56,10.56,0,0,1,4.533,4.534,10.56,10.56,0,1,1,19.467,19.468"
            transform="translate(0 -0.001)"
            fill="#3f3f3f"
          />
          <path
            id="Path_15869"
            data-name="Path 15869"
            d="M197.975,154.183l-4.145-4.145a.733.733,0,0,0-1.036,1.036l3.642,3.642-3.642,3.642a.733.733,0,0,0,1.036,1.036l4.145-4.145a.773.773,0,0,0,0-1.068"
            transform="translate(-183.385 -142.718)"
            fill="#3f3f3f"
          />
        </g>
      </g>
    </svg>
  )
}
