import React from "react"
import { useGeneralSettings } from "hooks/useGeneralSettings"

export const ContactNumberGrid = () => {
  const { trackingNumbers, usePhoneNumberNotTrackingNumber } =
    useGeneralSettings()
  return (
    <div className="side-nav__tracking-container">
      {trackingNumbers.map(({ trackingNumber, id, phoneNumber, label }) => {
        if (usePhoneNumberNotTrackingNumber && !phoneNumber) return ""
        if (!usePhoneNumberNotTrackingNumber && !trackingNumber) return ""
        return (
          <div key={id} className="side-nav__tracking-line">
            {label && <p>{label}:</p>}
            <a
              href={`tel: ${
                usePhoneNumberNotTrackingNumber ? phoneNumber : trackingNumber
              }`}
              className="side-nav__tracking-number"
            >
              {usePhoneNumberNotTrackingNumber ? phoneNumber : trackingNumber}
            </a>
          </div>
        )
      })}
    </div>
  )
}
