import React from "react"
import { useSideNav } from "hooks/useSideNav"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import "./side-nav.module.scss"
import { RemoveScrollBar } from "react-remove-scroll-bar"
import { FocusOn } from "react-focus-on"
import { SideNavOverlay } from "./SideNavOverlay"

interface SideNavProps {
  children: React.ReactNode
  isSideNavHovered: boolean
  setSideNaveHovered: (isHovered: boolean) => void
  handleOnClose: () => void
}

export const SideNavContainer = ({
  isSideNavHovered,
  setSideNaveHovered,
  handleOnClose,
  children,
}: SideNavProps) => {
  const asideRef = React.useRef<HTMLElement>(null)
  const overlayRef = React.useRef<HTMLDivElement>(null)
  const { isClosing, handleCloseNav } = useSideNav()
  const { isScrolled } = useWindowDimensions()
  const { sideNavBackgroundColor } = useColorAreaSettings()
  const scrollClass = isScrolled ? "side-nav--scroll-version" : ""

  const overrideBackground = sideNavBackgroundColor
    ? `${sideNavBackgroundColor}--backgroundColor`
    : ""

  return (
    <>
      <RemoveScrollBar />

      <aside
        ref={asideRef}
        onMouseEnter={() => {
          !isSideNavHovered && setSideNaveHovered(true)
        }}
        className={`side-nav ${
          isClosing ? "side-nav--close-nav" : ""
        } ${scrollClass} ${overrideBackground} scroll`}
      >
        <FocusOn
          shards={[asideRef, overlayRef]}
          scrollLock={false}
          onClickOutside={handleCloseNav}
          onEscapeKey={handleCloseNav}
        >
          <div className="fr-container fr-container--large side-nav__inner">
            {children}
          </div>
        </FocusOn>
      </aside>
      <SideNavOverlay
        ref={overlayRef}
        handleClose={handleOnClose}
        isSideNavHovered={isSideNavHovered}
      />
    </>
  )
}
