import React from "react"
import { Link } from "gatsby"
import "./main-navigation.module.scss"
import { NavigationLinksOutput } from "contexts/settings/Navigation/navigation-types"
import { useLayout } from "contexts/components/LayoutContext"
import { ExternalLink } from "components/external-link/ExternalLink"

interface HeaderNavigationProps {
  navItem: NavigationLinksOutput
  index?: number
  navCount?: number
  isMain?: boolean
}

export const HeaderNavigation = ({
  navItem,
  isMain,
}: HeaderNavigationProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()
  const { slug, linkText, externalLink } = navItem
  const path = linkedPagesUrlPathCodex[slug] || "404"
  const newSlug = path.startsWith("/") ? path : `/${path}`

  if (!externalLink) {
    return (
      <Link
        to={newSlug}
        className={isMain ? `fr-header__link` : `secondary-header__link`}
        activeClassName={
          isMain
            ? "fr-header__link--current"
            : "secondary-header__link--current"
        }
      >
        {linkText}
      </Link>
    )
  } else {
    return (
      <ExternalLink
        href={externalLink}
        target="_blank"
        className={isMain ? `fr-header__link` : `secondary-header__link`}
      >
        {linkText}
      </ExternalLink>
    )
  }
}
