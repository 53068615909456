import React from "react"
import { FormStub } from "./FormStub"
import { graphql } from "gatsby"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { KontentFormStubBannerFormatted } from "./formStubBanner-types"
import "./form-stub.module.scss"
import { fullWidthClassName } from "react-remove-scroll-bar"
import { useWindowDimensions } from "hooks/useWindowDimensions"

interface StubProps {
  formStub: KontentFormStubBannerFormatted
  nextBlockHasHeading?: boolean
  showMobileOnly?: boolean
}

export const StubBanner = ({
  formStub,
  nextBlockHasHeading,
  showMobileOnly,
}: StubProps) => {
  const { heading, button, fields, icon } = formStub

  const { size } = useWindowDimensions()
  const smallerThanLaptop = size < 1366
  const CLASS_PREVENT_HORIZONTAL_SHIFT_WITH_SIDENAV_OPEN = `u-unset-margin u-flex-center-align ${
    smallerThanLaptop ? fullWidthClassName : ""
  }`

  const { trackingNumbers, usePhoneNumberNotTrackingNumber } =
    useGeneralSettings()

  return (
    <section
      className={`form-stub-banner ${
        showMobileOnly ? "form-stub-banner--only-show-on-mobile" : ""
      }`}
    >
      <div
        className={`fr-container form-stub-banner__content ${CLASS_PREVENT_HORIZONTAL_SHIFT_WITH_SIDENAV_OPEN}`}
      >
        <img
          className="form-stub-banner__icon"
          src={`${icon.url}?q=60&w=90`}
          alt={icon.description}
          loading="lazy"
        />
        <h3 className="form-stub-banner__heading">{heading}</h3>
        <FormStub field={fields} button={button} />
        {trackingNumbers && (
          <a
            className="button--outline-primary form-stub__button"
            href={`tel:${
              usePhoneNumberNotTrackingNumber
                ? trackingNumbers[0].phoneNumber
                : trackingNumbers[0].trackingNumber
            }`}
          >
            or Call{" "}
            {usePhoneNumberNotTrackingNumber
              ? trackingNumbers[0].phoneNumber
              : trackingNumbers[0].trackingNumber}
          </a>
        )}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment FormStubBannerFragment on kontent_item_form_stub_banner {
    id
    elements {
      icon {
        value {
          ...KenticoAssetElementFragment
        }
      }
      heading {
        value
      }
      subheading {
        value
      }
      fields {
        value {
          ...FormFieldFragment
        }
      }
      button {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
