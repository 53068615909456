import React, { ReactNode, useCallback, useState } from "react"
import { SecondaryHeaderContext } from "contexts/components/SecondaryHeaderContext"
import { SecondaryHeaderLaptop } from "./SecondaryHeaderLaptop"
import "./secondary-header.module.scss"

interface SecondaryHeaderProps {
  children?: ReactNode
}

export const SecondaryHeader = ({ children }: SecondaryHeaderProps) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const handleOpenModal = useCallback(() => {
    setModalOpen(true)
  }, [])
  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
  }, [])
  return (
    <section className="secondary-header">
      <SecondaryHeaderContext.Provider
        value={{
          handleCloseModal,
          handleOpenModal,
          isModalOpen,
        }}
      >
        <SecondaryHeaderLaptop>{children}</SecondaryHeaderLaptop>
      </SecondaryHeaderContext.Provider>
    </section>
  )
}
