import React, { ReactNode } from "react"
import { Link } from "gatsby"
import { getPhoneIcon } from "assets/helper/icons"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import { Button } from "components/button"
import { useSecondaryHeader } from "contexts/components/SecondaryHeaderContext"
import { TrackingNumberModal } from "components/modal/TrackingNumberModal/TrackingNumberModal"
import { AnchorNumber } from "./AnchorNumber"

interface SecondaryHeaderLaptopProps {
  children: ReactNode
}

export const SecondaryHeaderLaptop = ({
  children,
}: SecondaryHeaderLaptopProps) => {
  const { handleOpenModal, isModalOpen, handleCloseModal } =
    useSecondaryHeader()
  const {
    hasFieldroutesReviews,
    hasFieldroutesPortal,
    customLinkTextFieldroutesReviews,
    customLinkTextFieldroutesPortal,
  } = useIntegrationsSettings()

  const {
    trackingNumbers,
    customTrackingNumberCtaButtonText,
    usePhoneNumberNotTrackingNumber,
  } = useGeneralSettings()

  const trackingNumberCTAText =
    customTrackingNumberCtaButtonText || "Call Today"

  return (
    <>
      <div className="fr-container fr-container--large secondary-header__inner secondary-header__inner--laptop">
        <div className="secondary-header__portal-links">
          {hasFieldroutesReviews && (
            <Link to="/reviews" className="secondary-header__portal-link">
              {customLinkTextFieldroutesReviews
                ? customLinkTextFieldroutesReviews
                : "Customer Reviews"}
            </Link>
          )}
          {hasFieldroutesPortal && (
            <a href="/login" className="secondary-header__portal-link">
              {customLinkTextFieldroutesPortal
                ? customLinkTextFieldroutesPortal
                : "Customer Login"}
            </a>
          )}
        </div>
        {children}

        {trackingNumbers.length > 0 &&
          trackingNumbers.length <= 3 &&
          trackingNumbers.map(
            ({ trackingNumber, phoneNumber, id, label }, i) => {
              if (usePhoneNumberNotTrackingNumber && !phoneNumber) return ""
              if (!usePhoneNumberNotTrackingNumber && !trackingNumber) return ""
              return (
                <AnchorNumber
                  key={id}
                  classNameModifier={
                    i === 0 ? " secondary-header__phone-link--first" : ""
                  }
                  phoneNumber={phoneNumber}
                  trackingNumber={trackingNumber}
                  label={label}
                  showIcon={false}
                />
              )
            }
          )}
        {trackingNumbers.length >= 4 && (
          <>
            <Button
              variant="flashy-icon"
              extraClassNames="secondary-header__phone-button"
              onClick={handleOpenModal}
            >
              {getPhoneIcon()}
              {trackingNumberCTAText}
            </Button>

            {/* START - This hidden content is here to have the tracking numbers for crawlers when the modal is not open */}
            {!isModalOpen &&
              trackingNumbers.map(
                ({ trackingNumber, phoneNumber, id, label }) => {
                  if (usePhoneNumberNotTrackingNumber && !phoneNumber) return ""
                  if (!usePhoneNumberNotTrackingNumber && !trackingNumber)
                    return ""
                  return (
                    <AnchorNumber
                      key={id}
                      classNameModifier="--hidden"
                      phoneNumber={phoneNumber}
                      trackingNumber={trackingNumber}
                      label={label}
                      showIcon={false}
                    />
                  )
                }
              )}
            {/* END - Tracking Numbers for crawlers */}
          </>
        )}
      </div>
      {trackingNumbers.length >= 4 && isModalOpen && (
        <div className={`fr-tracking-number-modal`}>
          <TrackingNumberModal onCloseModal={handleCloseModal} />
        </div>
      )}
    </>
  )
}
